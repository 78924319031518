<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="bars"></nb-icon>
    </a>
    <a class="logo d-none d-sm-block" href="#" (click)="navigateHome()">
      ابتدائية
      <span>ابتسم</span>
      الاهلية
    </a>
  </div>
  <nb-select
    [selected]="currentTheme"
    size="small"
    id="theme-select"
    (selectedChange)="changeTheme($event)"
    status="primary"
    class="px-0"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select>
  <div class="full-screen" (click)="toggleFullScreen()">
    <ng-container *ngIf="!fullScreen; else shrinkTemp">
      <nb-icon icon="expand"></nb-icon>
    </ng-container>
    <ng-template #shrinkTemp>
      <nb-icon icon="compress"></nb-icon>
    </ng-template>
  </div>
</div>
<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <span class="user-name">
        <span class="user-name-span">{{ user?.fullname }}</span> -
        {{ arabicPrivileges[user.type] }}
      </span>
    </nb-action>
    <nb-action *ngIf="studyYear?.selectedStudyYear" class="px-0">
      <nb-select
        fullWidth
        size="small"
        [selected]="studyYear.selectedStudyYear"
        (selectedChange)="changeStudyYear($event)"
      >
        <nb-option *ngFor="let year of studyYear.studyYears" [value]="year">{{
          year.title
        }}</nb-option>
      </nb-select>
    </nb-action>
  </nb-actions>
</div>
